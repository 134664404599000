import { BrowserRouter as Router, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { AppContext, useAppStoreProvider } from '@src/data/AppContext'
import { OriginationTracking } from '@src/types/origination'
import { frCA, enCA } from 'date-fns/locale'
import { setDefaultOptions } from 'date-fns'
import { ReactNode } from 'react'
import TopLevelRoutes from './Routes'
import Header from './components/Header'
import ScrollToTop from './components/ScrollToTop'
import Footer from './components/Footer'

const App = (): ReactNode => {
  const { id: crediAppId } = useParams<{ id: string }>()
  const { i18n } = useTranslation()
  const searchParams = new URLSearchParams(window.location.search)
  let origination: OriginationTracking | null = null
  const sourceId = searchParams.get('sid')
  const campaignId = searchParams.get('cid')
  const mediumId = searchParams.get('mid')
  const brandId = searchParams.get('br')
  if (sourceId && campaignId && mediumId && brandId) {
    origination = { sourceId, campaignId, mediumId, brandId }
  }

  if (i18n.resolvedLanguage === 'fr') setDefaultOptions({ locale: frCA })
  else if (i18n.resolvedLanguage === 'en') setDefaultOptions({ locale: enCA })

  const appStore = useAppStoreProvider(origination, crediAppId)

  return (
    <AppContext.Provider value={appStore}>
      <Router>
        <ScrollToTop />
        <Header language={i18n.language} />
        <TopLevelRoutes />
        <Footer />
      </Router>
    </AppContext.Provider>
  )
}

export default App
