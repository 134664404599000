import { useTranslation } from 'react-i18next'
import { EApplicationStep } from '@src/types'
import UploadFilesTable from '@src/components/UploadFilesTable'
import { FilteredCreditApplication } from '@src/api/types/FilteredCreditApplication'
import { hasMissingDocuments } from '@src/data/creditapp-selectors'
import SectionTitle from '@src/components/PageSectionTitle'
import UnderReviewTile from '../../components/UnderReviewTile'

interface Props {
  creditApp: FilteredCreditApplication
}
const RequiredDocForDecisionForm = ({ creditApp }: Props) => {
  const { t } = useTranslation()
  const hasMissingDocs = hasMissingDocuments(creditApp, EApplicationStep.Credit)
  return hasMissingDocs ? (
    <main className="general-message">
      {hasMissingDocs && (
        <div>
          <SectionTitle title={t('common.financeApplication')} />
          <h1>{t('decision.creditDocumentRequiredTitle')}</h1>
          <div className="paragraph">
            <p>{t('decision.creditDocumentRequired')}</p>
            <p>{t('identity.notifyEmail')}</p>
          </div>
          <UploadFilesTable creditApp={creditApp} />
        </div>
      )}
    </main>
  ) : (
    <UnderReviewTile />
  )
}

export default RequiredDocForDecisionForm
