import { useParams, useNavigate } from 'react-router-dom'

import { useTranslation } from 'react-i18next'
import AcceptTermsCheckbox from '@src/components/AcceptTermsCheckbox'
import BackButton from '@src/components/BackButton'
import NeedHelp from '@src/components/NeedHelp'
import SectionTitle from '@src/components/PageSectionTitle'
import StepCardContainer from '@src/components/Card/CardContainer'
import StepCard from '@src/components/Card/Card'

const FlinksPage = (): JSX.Element => {
  const params = useParams()
  const id = params.id!
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()

  const handleContinueToFlinks = (evt: { preventDefault: () => void }) => {
    evt.preventDefault()
    navigate(`/creditapplication/${id}/flinks/validate`)
  }

  return (
    <main id="flinks-how-it-works" className="general-message">
      <form onSubmit={handleContinueToFlinks}>
        <SectionTitle title={t('common.incomeVerification')} />
        <h1>{t('flinks.ValidateIncomeTitle')}</h1>
        <span style={{ margin: '1rem 0' }}>
          <h4>{t('flinks.whatIsFlinks')}</h4>
        </span>
        <StepCardContainer>
          <StepCard
            icon="building-columns"
            title={t('flinks.firstStep')}
            description={t('flinks.firstStepDescription')}
          />
          <StepCard icon="money-check" title={t('flinks.secondStep')} description={t('flinks.secondStepDescription')} />
          <StepCard
            icon="magnifying-glass-chart"
            title={t('flinks.thirdStep')}
            description={t('flinks.thirdStepDescription')}
          />
        </StepCardContainer>
        <AcceptTermsCheckbox msgOnInvalid={t('common.acceptConditions')} id="acceptAuthorise">
          {i18n.language === 'fr' && (
            <p className="blue-strong">
              J&apos;ai lu et j&apos;accepte les{' '}
              <strong>
                <a href={`/creditapplication/${id}/flinks/terms`}>conditions générales</a>
              </strong>{' '}
              d&apos;iFinance
            </p>
          )}
          {i18n.language === 'en' && (
            <p className="blue-strong">
              I have read and agree to iFinance&apos;s{' '}
              <strong>
                <a href={`/creditapplication/${id}/flinks/terms`}>Terms and Conditions</a>
              </strong>
            </p>
          )}
        </AcceptTermsCheckbox>
        <NeedHelp creditApplicationId={id} description="Flinks" />
        <div className="btn-group mobile-reversed space-between">
          <BackButton />
          <button type="submit" className="btn primary-action-btn btn-blue btn-simple">
            <span>{t('flinks.continueFlinks')}</span>
            <i className="fa-regular fa-arrow-right" />
          </button>
        </div>
      </form>
    </main>
  )
}

export default FlinksPage
