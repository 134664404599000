import { useAppStore } from '@src/data/AppContext'
import useFaqLink from '@src/hooks/useFaqLink'
import { Constants } from '@src/types'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'

interface HeaderProps {
  language: string
}

const Header = ({ language }: HeaderProps) => {
  const { t, i18n } = useTranslation()
  const appStore = useAppStore()
  const targetLanguage = t('common.targetLanguage')

  const needToHelpUrl = `${Constants.Host.Ifinance}/${language}/contact/`
  const faqLink = useFaqLink()
  const imgSrc =
    appStore.origination?.campaignId === '1' && appStore.origination?.brandId === '3'
      ? 'https://icebergstatic.blob.core.windows.net/b2c-merchant-logos/1-3.png'
      : 'https://icebergstatic.blob.core.windows.net/b2c-merchant-logos/1-1.png'

  return (
    <header className="header" style={{ background: 'white' }}>
      <div className="wrap">
        <figure className="logo">
          <div className="left">
            <a href={Constants.Host.Ifinance} target="_blank" className="btn-simple" rel="noreferrer">
              <img style={{ maxHeight: '70px' }} id="logo" src={imgSrc} alt="Logo" />
            </a>
            <img
              style={{ maxWidth: '20rem' }}
              src={`/images/in_partnership_${language}.svg`}
              alt="in partnership iFinance"
            />
          </div>
        </figure>
        <div className="right">
          <a href={faqLink} target="_blank" className="btn-simple" rel="noreferrer">
            {t('common.FAQ')}
          </a>
          <a href={needToHelpUrl} target="_blank" className="btn-simple" rel="noreferrer">
            {t('common.needHelp')}
          </a>

          <button
            type="button"
            style={{ padding: '1rem 2rem' }}
            className="btn btn-blue"
            onClick={() => i18n.changeLanguage(targetLanguage)}
          >
            {targetLanguage}
          </button>
        </div>
      </div>
    </header>
  )
}

export default memo(Header)
