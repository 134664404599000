import { useTranslation } from 'react-i18next'

interface Props { title?: string; description?: string; progression?: number }

const Loader = ({ title, description, progression }: Props) => {
  const { t } = useTranslation()
  return (
    <div className="loader-wrap">
      <div className="loader-box">
        <div className="loader" />
        {progression !== undefined && (
          <div>
            <div className="progress-container">
              <div className="progress-bar" style={{ width: `${progression}%` }} />
            </div>
            <div className="progress-number">{Math.floor(progression)}%</div>
          </div>
        )}

        <h3>{title ?? t('common.withYouShortly')}</h3>
        <div className="paragraph">
          <p>{description ?? t('common.pleaseWait')}</p>
        </div>
      </div>
    </div>
  )
}

export default Loader
