import { useEffect, useRef, useState } from 'react'

interface Props {
  id: string
  termId?: string
  msgOnInvalid: string
  children?: React.ReactNode
}

const AcceptTermsCheckbox = ({ id, children, termId, msgOnInvalid }: Props) => {
  const [showError, setShowError] = useState(false)
  const targetRef = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    if (showError && targetRef.current) {
      targetRef.current.focus()
      targetRef.current.scrollIntoView()
    }
  }, [showError])

  return (
    <div className="control-group">
      <input
        required
        type="checkbox"
        id={id}
        onChange={(e) => {
          if (e.currentTarget.checked) {
            setShowError(false)
          }
        }}
        onInvalid={(e) => {
          e.preventDefault()
          setShowError(true)
        }}
        ref={targetRef}
      />
      <label
        htmlFor={id}
        style={{
          fontSize: '1.8rem',
          fontWeight: 'inherit',
        }}
      >
        {termId && termId}
        {children}
      </label>
      {showError && (
        <div
          style={{
            color: 'red',
            fontSize: '1.4rem',
            marginTop: '0.5rem',
          }}
        >
          {msgOnInvalid}
        </div>
      )}
    </div>
  )
}

export default AcceptTermsCheckbox
