import React, { useState } from 'react'
import { FilteredCreditApplication } from '@src/api/types/FilteredCreditApplication'
import { Trans, useTranslation } from 'react-i18next'

import { ETrackedEvents, trackEvent } from '@src/services/trackingEvents'

interface Props {
  creditApp: FilteredCreditApplication
}

const CreditFileLockedTile = ({ creditApp }: Props) => {
  const { t } = useTranslation()
  const [toggle, setToggle] = useState<boolean>(false)

  React.useEffect(() => {
    trackEvent(ETrackedEvents.QualifDeclined, creditApp)
  }, [creditApp])
  return (
    <main className="general-message" style={{ display: 'block' }}>
      <h1>{t('decision.lockedCreditReport.header')}</h1>
      <ul className="h3" style={{ margin: '2rem auto' }}>
        {creditApp.applicant.hasLockedCreditReport && (
          <li
            style={{
              color: '#08abf0',
              border: '1px solid #08abf0',
              borderRadius: '1rem',
              padding: '1rem',
              margin: '1rem auto',
            }}
          >
            <Trans
              i18nKey="decision.lockedCreditReport.message"
              values={{
                applicantType: t('common.applicant'),
              }}
            />
          </li>
        )}
      </ul>
      <h3>
        {t('decision.lockedCreditReport.main')}
        <button
          style={{ textDecoration: 'underline', cursor: 'pointer', color: '#08abf0', backgroundColor: 'transparent' }}
          onClick={() => setToggle(!toggle)}
        >
          {t('decision.lockedCreditReport.creditReportProvider')}
        </button>
      </h3>
      {toggle && (
        <div style={{ margin: '1rem auto' }}>
          <p
            style={{
              fontStyle: 'italic',
              border: '1px solid #08abf0',
              borderRadius: '1.5rem',
              textAlign: 'center',
              padding: '1.5rem',
            }}
          >
            {t('decision.lockedCreditReport.contactEquifax')}
          </p>
        </div>
      )}
      <h3>{t('decision.lockedCreditReport.footer')}</h3>
    </main>
  )
}

export default CreditFileLockedTile
