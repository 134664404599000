import { Constants } from '@src/types'
import yupExtInt from '@src/types/schemas/common/SchemaTypes'
import * as yup from '@src/types/schemas/common/yup-extended'
import { t } from 'i18next'
import { useMemo } from 'react'

function buildWorksheetSchema(availableCredit: number, amountRequested: number, term: number) {
  return yup.default.object({
    amountRequested: yupExtInt.double
      .required()
      .default(amountRequested)
      .min(Constants.MinLoanAmount, () =>
        t('worksheet.error.between', { min: Constants.MinLoanAmount, max: availableCredit }),
      )
      .max(availableCredit, () => t('worksheet.error.between', { min: Constants.MinLoanAmount, max: availableCredit })),
    term: yupExtInt.integer.required().default(term),
    firstPaymentOn: yup.default.string().isValidDate().required(),
    includeInsurance: yup.default.boolean().required(),
  })
}
export function useWorksheetSchema(availableCredit: number, amountRequested: number, term: number) {
  return useMemo(
    () => buildWorksheetSchema(availableCredit, amountRequested, term),
    [availableCredit, amountRequested, term],
  )
}

export type WorksheetForm = yup.default.InferType<ReturnType<typeof buildWorksheetSchema>>
