import { FilteredCreditApplication } from '@src/api/types/FilteredCreditApplication'
import { useEffect } from 'react'
import ReactPixel from 'react-facebook-pixel'
import ReactGA from 'react-ga4'

interface EventParams {
  reference_number?: number
  credit_application_id?: string
  amount?: number
}

export enum ETrackedEvents {
  GetStarted = 'get_started',
  PrequalSuccess = 'prequal_success',
  PrequalDeclined = 'prequal_declined',
  QualifSuccess = 'qualif_success',
  QualifDeclined = 'qualif_declined',
  WithdrewManualRevision = 'withdrew_manual_revision',
  WithdrewAfterPrequal = 'withdrew_after_prequal',
  WithdrewAfterQualif = 'withdrew_after_qualif',
  CancelPrequal = 'cancel_prequal',
}

export function trackEvent(eventName: ETrackedEvents, creditApp: FilteredCreditApplication) {
  let amount = creditApp.requestedLoanAmount
  if (creditApp?.normsDecision) {
    amount = Math.min(creditApp.requestedLoanAmount, creditApp.finalDecision.maxAmountFinanced)
  } else if (creditApp?.prequalificationDecision) {
    amount = Math.min(creditApp.requestedLoanAmount, creditApp.prequalificationDecision.maxLoanAmount)
  }

  const params: EventParams = {
    reference_number: creditApp.referenceNumber,
    credit_application_id: creditApp.id,
    amount,
  }

  const eventID = `${eventName}-${creditApp.id}`
  ReactPixel.fbq('trackCustom', eventName, params, { eventID })
  ReactGA.event(eventName, { ...params, transaction_id: eventID })
}

export const useTrackStepReachedEvent = (
  creditApp: FilteredCreditApplication | null,
  eventName: ETrackedEvents,
): void => {
  useEffect(() => {
    if (creditApp) {
      trackEvent(eventName, creditApp)
    }
  }, [creditApp, eventName])
}
