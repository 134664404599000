import { FilteredCreditApplication } from '@src/api/types/FilteredCreditApplication'
import BackButton from '@src/components/BackButton'
import UploadFilesTable from '@src/components/UploadFilesTable'
import useRequiredDocumentsWithStatus from '@src/data/creditapp-hooks'
import { areAllRequiredDocumentsApproved } from '@src/data/creditapp-selectors'
import { Navigate } from 'react-router-dom'

interface Props {
  creditApp: FilteredCreditApplication
}

const DocumentsPage = ({ creditApp }: Props) => {
  const requiredDocumentsWithStatusList = useRequiredDocumentsWithStatus(
    creditApp.requiredDocuments,
    creditApp.documents,
  )
  if (areAllRequiredDocumentsApproved(requiredDocumentsWithStatusList)) return <Navigate to=".." replace />
  return (
    <main className="general-message">
      <UploadFilesTable creditApp={creditApp} />
      <BackButton href=".." />
    </main>
  )
}

export default DocumentsPage
