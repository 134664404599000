import translate from '@src/services/translate'
import React, { ReactNode } from 'react'

interface Props {
  toggleText: string
  children: ReactNode
}
const ExpandableSection = ({ toggleText, children }: Props) => {
  const [isOpen, setIsOpen] = React.useState(false)
  return (
    <div>
      <button type="button" className="btn-collapse" onClick={() => setIsOpen(!isOpen)}>
        <i
          className={isOpen ? 'fa-solid fa-chevron-up' : 'fa-solid fa-chevron-down'}
          style={{ marginRight: '0.5rem' }}
        />
        <span>{translate(toggleText)}</span>
      </button>
      {isOpen && children}
    </div>
  )
}

export default ExpandableSection
