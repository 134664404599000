import { areAllRequiredDocumentsApproved, hasMissingDocuments } from '@src/data/creditapp-selectors'
import { useTranslation } from 'react-i18next'
import { useLayoutEffect } from 'react'
import { scrollToTop } from '@src/services/utils'
import { FilteredCreditApplication } from '@src/api/types/FilteredCreditApplication'
import useRequiredDocumentsWithStatus from '@src/data/creditapp-hooks'
import CreditStep, { CreditStepStatus } from './CreditStep'

interface DocumentsCreditStepProps {
  creditApp: FilteredCreditApplication
}
const DocumentsCreditStep = ({ creditApp }: DocumentsCreditStepProps) => {
  const { t } = useTranslation()
  const requiredDocumentsWithStatusList = useRequiredDocumentsWithStatus(
    creditApp.requiredDocuments,
    creditApp.documents,
  )
  const allDocsAreApproved = areAllRequiredDocumentsApproved(requiredDocumentsWithStatusList)
  const isMissingDocs = hasMissingDocuments(creditApp)
  let status: CreditStepStatus
  let desc
  if (isMissingDocs) {
    status = CreditStepStatus.RequiresAttention
    desc = 'overview.moreDocs' as const
  } else if (allDocsAreApproved) {
    status = CreditStepStatus.Completed
    desc = 'overview.noDocuments' as const
  } else {
    status = CreditStepStatus.Completed
    desc = 'overview.documentReview' as const
  }

  useLayoutEffect(scrollToTop, [])
  return (
    <CreditStep
      status={status}
      linkToDetail="required-documents"
      hideLinkToDetail={status === CreditStepStatus.Completed}
      title={t('overview.documentsStep')}
      content={t(desc)}
    />
  )
}

export default DocumentsCreditStep
