import { Constants } from '@src/types'
import yupExtInt from '@src/types/schemas/common/SchemaTypes'
import * as yup from '@src/types/schemas/common/yup-extended'

export const buildEditSinAndLoanAmountSchema = (maxLoanAmount: number) => {
  return yup.default.object({
    id: yup.default.string().default(''),
    needsApplicantSin: yup.default.bool().default(false),
    needsCoapplicantSin: yup.default.bool().default(false),
    applicantSin: yup.default
      .string()
      .nullable()
      .when('needsApplicantSin', {
        is: true,
        then: (schema) => schema.required().SinType(),
        otherwise: (schema) => schema.notRequired(),
      }),
    requestedLoanAmount: yupExtInt.double.required().default(0).min(Constants.MinLoanAmount).max(maxLoanAmount),
  })
}

export type EditSinAndLoanAmount = yup.default.InferType<ReturnType<typeof buildEditSinAndLoanAmountSchema>>
