import { ComputeAmountForFrequencyDto, MonthlyPayment, useComputePaymentAmountForFrequency } from '@src/api/compute-api'
import { FilteredCreditApplication } from '@src/api/types/FilteredCreditApplication'
import { formatDate } from '@src/services/Formatter'
import { EMPTY_ARRAY, EPaymentFrequency } from '@src/types'
import { addMonths } from 'date-fns'
import { useCallback, useMemo, useState } from 'react'

export interface TermPaymentOption {
  firstPaymentOn?: string | null
  activationDate?: Date | null
  requestedAmount?: number | null
  paymentPlanId?: string | null
  includeInsurance?: boolean
}

const useTermPaymentsTable = (
  creditApp: FilteredCreditApplication | null,
  option?: TermPaymentOption,
): [MonthlyPayment[], boolean] => {
  const dto = useMemo<ComputeAmountForFrequencyDto | null>(() => {
    if (creditApp) {
      return {
        interestRate: creditApp.finalDecision.interestRate,
        stateIso: creditApp.applicant.province,
        paymentFrequency: EPaymentFrequency.Monthly,
        deliveryOn: formatDate(option?.activationDate ?? new Date()),
        firstPaymentOn: option?.firstPaymentOn ?? formatDate(addMonths(new Date(), 1)),
        maxPmtAmount: creditApp.finalDecision.maxPmtAmount,
        amountRequested:
          option?.requestedAmount ?? Math.min(creditApp.requestedLoanAmount, creditApp.finalDecision.maxAmountFinanced),
        includeInsurance: option?.includeInsurance ?? false,
      }
    }
    return null
  }, [option, creditApp])
  const [result, isLoading] = useComputePaymentAmountForFrequency(dto)
  const filteredTermAmountPairs = result?.monthlyTerms.filter(
    (ta) => ta.term <= (creditApp?.finalDecision?.maxTermDuration ?? 0),
  )
  return [filteredTermAmountPairs ?? EMPTY_ARRAY, isLoading]
}

export default useTermPaymentsTable
export const useSessionStorage = <T,>(keyName: string, initialValue: T) => {
  const savedInfo = sessionStorage.getItem(keyName)
  const initValue = savedInfo ? (JSON.parse(savedInfo) as T) : initialValue

  const [value, setValue] = useState<T>(initValue)

  const saveValue = useCallback(
    (newValue: T) => {
      const json = JSON.stringify(newValue)
      sessionStorage.setItem(keyName, json)
      setValue(newValue)
    },
    [keyName],
  )

  return [value, saveValue] as const
}
