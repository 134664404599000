import { BlockBlobClient } from '@azure/storage-blob'
import { reportErrorToServer, reportTraceToServer } from '@src/services/error-logger'
import { EApplicantType, EDocumentType } from '@src/types'
import { AxiosRequestConfig } from 'axios'
import apiClient from './api-client'

interface SASTokenUrlGeneration {
  applicantType: EApplicantType
  status: string
  subKey: string
  typeId: number
  fileSASTokenUrls: Record<string, string>
}

export interface UploadFilesResultDto {
  creditApplicationId: string
  applicantType: EApplicantType
  typeId: number
  subKey: string
  status: string
}

export interface UploadFilesDto {
  creditApplicationId: string
  applicantType: EApplicantType
  typeId: EDocumentType
  subKey: string | null
  files: File[]
  recaptcha: string
}

const getSASTokenUrl = async (creditApplicationId: string, data: SASTokenUrlGeneration, recaptcha: string) => {
  const payload: AxiosRequestConfig<SASTokenUrlGeneration> = {
    method: 'POST',
    url: `api/creditApplication/${creditApplicationId}/GenerateSASTokenUrl`,
    data,
    headers: {
      'x-recaptcha-token': recaptcha,
    },
  }
  const response = await apiClient.request(payload)
  return response.data as SASTokenUrlGeneration
}

const convertStringToArrayBuffer = (str: string) => {
  const textEncoder = new TextEncoder()
  return textEncoder.encode(str).buffer as ArrayBuffer
}

function convertFileToArrayBuffer(file: File): Promise<ArrayBuffer | null> {
  return new Promise((resolve, reject) => {
    if (!file?.name) {
      reject(new Error('Invalid or missing file.'))
    }

    const reader = new FileReader()

    reader.onload = () => {
      const arrayBuffer: ArrayBuffer | null | string = reader.result

      if (arrayBuffer === null) {
        resolve(null)
        return
      }
      if (typeof arrayBuffer === 'string') {
        resolve(convertStringToArrayBuffer(arrayBuffer))
        return
      }
      if (!arrayBuffer) {
        reject(new Error('Failed to read file into ArrayBuffer.'))
        return
      }

      resolve(arrayBuffer)
    }

    reader.onerror = () => {
      reject(new Error('Error reading file.'))
    }

    reader.readAsArrayBuffer(file)
  })
}

export const uploadFiles = async (dto: UploadFilesDto) => {
  const sasDto = {
    applicantType: dto.applicantType,
    subKey: dto.subKey,
    typeId: dto.typeId,
    fileSASTokenUrls: {},
  } as SASTokenUrlGeneration
  dto.files.forEach((file, index) => {
    sasDto.fileSASTokenUrls[`${index}-${file.name}`] = ''
  })

  const sasTokens = await getSASTokenUrl(dto.creditApplicationId, sasDto, dto.recaptcha)

  try {
    await Promise.all(
      dto.files.map(async (file, index) => {
        const fileArrayBuffer = await convertFileToArrayBuffer(file)
        if (fileArrayBuffer === null || fileArrayBuffer.byteLength < 1) {
          return
        }
        const concernedSASUrl = sasTokens.fileSASTokenUrls[`${index}-${file.name}`]
        const blockBlobClient = new BlockBlobClient(concernedSASUrl)
        await blockBlobClient.uploadData(fileArrayBuffer)
      }),
    )
  } catch (error) {
    const traceMessage = `Error uploading files for credit application ${
      dto.creditApplicationId
    } with SASToken ${JSON.stringify(sasTokens)}`
    reportTraceToServer(traceMessage)
    reportErrorToServer(error as Error)
    throw error
  }

  return {
    creditApplicationId: dto.creditApplicationId,
    applicantType: dto.applicantType,
    typeId: dto.typeId,
    subKey: dto.subKey,
    status: sasTokens.status,
  } as UploadFilesResultDto
}
