import { translateErrorCode } from '@src/types'
import { TOptionsBase } from 'i18next'
import { $Dictionary } from 'node_modules/i18next/typescript/helpers'
import React, { LegacyRef } from 'react'
import { FieldError } from 'react-hook-form'

type Props = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
  error?: FieldError
  label?: string
  type?: 'text' | 'number'
  wrapStyle?: string
  errorParams?: TOptionsBase & $Dictionary
}
const InputText = React.forwardRef<Props, Props>(
  ({ id, error, label, wrapStyle, type = 'text', errorParams, ...otherProps }: Props, ref) => {
    return (
      <div className={`control-group ${error && 'error'}`}>
        {label && <label htmlFor={id}>{label}</label>}
        <div className={wrapStyle}>
          <input id={id} type={type} {...otherProps} ref={ref as LegacyRef<HTMLInputElement>} />
        </div>
        {error && <p className="error-message">{translateErrorCode(error, errorParams)}</p>}
      </div>
    )
  },
)

InputText.displayName = 'InputText'

export default InputText
