import { applicantFullName, areApplicantIncomesUnderReview } from '@src/data/creditapp-selectors'
import { useTranslation } from 'react-i18next'
import { FilteredCreditApplication } from '@src/api/types/FilteredCreditApplication'
import CreditStep, { CreditStepStatus } from './CreditStep'

interface IncomeCreditStepProps {
  creditApp: FilteredCreditApplication
}

const IncomeCreditStep = ({ creditApp }: IncomeCreditStepProps) => {
  const { t } = useTranslation()
  const title = t('overview.incomeStep', { name: applicantFullName(creditApp.applicant) })

  const areIncomePendingReview = areApplicantIncomesUnderReview(creditApp)

  const status =
    creditApp.applicant.areIncomesConfirmed || areIncomePendingReview
      ? CreditStepStatus.Completed
      : CreditStepStatus.RequiresAttention

  const description = creditApp.applicant.areIncomesConfirmed
    ? 'overview.incomeConfirmed'
    : areIncomePendingReview
      ? 'overview.incomeReview'
      : 'overview.incomeVerify'

  return (
    <CreditStep
      status={status}
      linkToDetail="incomes"
      hideLinkToDetail={status === CreditStepStatus.Completed}
      title={title}
      content={t(description)}
    />
  )
}

export default IncomeCreditStep
