import '@src//containers/App/App.css'
import App from '@src/containers/App/App'
import '@src/index.css'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import i18n from 'i18next'
import { createRoot } from 'react-dom/client'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { I18nextProvider } from 'react-i18next'
import initializeFPixel from './FPixel'
import initializeGAnalytics from './GAnalytics'
import initializeAppInsights from './services/AppInsights'
import { reportErrorToConsole, setupTracking } from './services/error-logger'
import { initializeI18N } from './services/i18n'

const ai = initializeAppInsights()
setupTracking(ai)

initializeGAnalytics()

initializeFPixel()

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
})
const container = document.getElementById('root')
const root = createRoot(container!)

initializeI18N()
  .then(() => {
    root.render(
      <GoogleReCaptchaProvider reCaptchaKey={import.meta.env.VITE_CAPTCHA_SITE_KEY || ''}>
        <I18nextProvider i18n={i18n}>
          <QueryClientProvider client={queryClient}>
            <App />
            <div
              style={{
                fontSize: '16px',
              }}
            >
              <ReactQueryDevtools initialIsOpen={false} buttonPosition="top-right" />
            </div>
          </QueryClientProvider>
        </I18nextProvider>
      </GoogleReCaptchaProvider>,
    )
  })
  .catch(reportErrorToConsole)
