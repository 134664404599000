import React, { useCallback } from 'react'
import { FilteredCreditApplication } from '@src/api/types/FilteredCreditApplication'
import { CustomerFeedbackDto, useGetFeedback, useSubmitFeedback } from '@src/api/feedback-api'
import { Loader } from '@src/components'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { EReviewLink } from '@src/types'
import { reportErrorToConsole } from '@src/services/error-logger'
import SectionTitle from '@src/components/PageSectionTitle'

interface Props {
  creditApp: FilteredCreditApplication
}

const CustomerFeedbackTile = ({ creditApp }: Props) => {
  const [feedback, isLoadingFeedback] = useGetFeedback(creditApp.id)

  const { t } = useTranslation()
  const navigate = useNavigate()
  const [submitFeedback] = useSubmitFeedback()

  const postFeedback = useCallback(() => {
    const dto: CustomerFeedbackDto = {
      withdrawalReasonId: null,
      badReviewReasonId: null,
      contactClient: false,
      isSatisfied: true,
      creditApplicationId: creditApp.id,
    }

    submitFeedback(dto).catch(reportErrorToConsole)
  }, [creditApp.id, submitFeedback])

  const navigateToBadReview = useCallback(() => {
    navigate(`/creditApplication/${creditApp.id}/badReview`, {
      replace: false,
    })
  }, [creditApp.id, navigate])

  if (isLoadingFeedback) return <Loader title={t('common.withYouShortly')} description={t('common.pleaseWait')} />

  return !feedback ? (
    <main className="step-0">
      <SectionTitle title={t('overview.rateExperienceStep')} />
      <h3>{t('feedback.rateYourExperience')}</h3>
      <div style={{ width: '100%' }}>
        <div className="button-group-2 feedback-btn-group">
          <div
            tabIndex={0}
            role="button"
            className="feedback-btn"
            onClick={postFeedback}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                postFeedback()
              }
            }}
          >
            <a href={EReviewLink.IFinance} target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>
              <i className="fa-light fa-thumbs-up" />
              <button type="button" className="btn btn-blue" style={{ minWidth: '100%' }}>
                {t('feedback.goodNews')}
              </button>
            </a>
          </div>
          <div
            tabIndex={0}
            role="button"
            className="feedback-btn"
            onClick={() => navigateToBadReview()}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                navigateToBadReview()
              }
            }}
          >
            <i className="fa-light fa-thumbs-down fa-flip-horizontal" />

            <button type="button" className="btn btn-blue" style={{ minWidth: '100%' }}>
              {t('feedback.badNews')}
            </button>
          </div>
        </div>
      </div>
    </main>
  ) : (
    <main className="general-message">
      <h3 style={{ marginBottom: '2rem' }}>{t('feedback.thankYouGoodReview')}</h3>
      <div className="btn-group full-width-mobile">
        <button
          type="button"
          onClick={() => navigate(`/creditapplication/${creditApp.id}`)}
          className="btn btn-blue primary-action-btn"
        >
          <i className="fa-light fa-arrow-left" />
          <span>{t('common.backToApplication')}</span>
        </button>
      </div>
    </main>
  )
}

export default React.memo(CustomerFeedbackTile)
