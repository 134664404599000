import { QueryFunctionContext, useQuery } from '@tanstack/react-query'
import { EProvince } from '@src/types'
import apiClient from './api-client'

const AMOUNT_FOR_FREQUENCY = 'amountForFrequency'

const keysFactory = {
  amountForFrequency: (computeParams: ComputeAmountForFrequencyDto | null) =>
    [{ scope: AMOUNT_FOR_FREQUENCY }, computeParams] as const,
}

// -----------------------------------  computeAmountForFrequency  ------------------------
export interface ComputeAmountForFrequencyDto {
  interestRate: number
  stateIso: EProvince
  paymentFrequency: string
  deliveryOn: string
  firstPaymentOn: string
  maxPmtAmount: number
  amountRequested: number
  includeInsurance: boolean
}

export interface ComputedAmountForFrequency {
  monthlyTerms: MonthlyPayment[]
}

export interface MonthlyPayment {
  term: number
  effectiveRateWithoutInsurance: number
  effectiveRateWithInsurance: number
  effectiveRateWithInsuranceIsLegal: boolean
  effectiveRateWithoutInsuranceIsLegal: boolean
  amountWithoutInsurance: number
  amountWithInsurance: number
  insuranceDelta: number
}

const computePaymentAmountForFrequency = async ({
  queryKey: [_, computeParams],
}: QueryFunctionContext<ReturnType<(typeof keysFactory)['amountForFrequency']>>) => {
  const response = await apiClient.post('api/CreditApplication/computeAmountForFrequency', computeParams)
  return response.data as ComputedAmountForFrequency
}

export function useComputePaymentAmountForFrequency(
  computeParams: ComputeAmountForFrequencyDto | null,
): [ComputedAmountForFrequency | null, boolean] {
  const { isFetching, data } = useQuery({
    queryKey: [...keysFactory.amountForFrequency(computeParams)],
    queryFn: computePaymentAmountForFrequency,
    enabled: computeParams !== null,
  })

  return [data ?? null, isFetching]
}
