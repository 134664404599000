import { useState, useEffect, useMemo } from 'react'
import { FilteredCreditApplication } from '@src/api/types/FilteredCreditApplication'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useDownloadVouchedReport, usePrequalify } from '@src/api/credit-api'
import { VouchedConfig } from '@src/types/vouched/vouched-types'
import {
  IdentityVerificationSession,
  useResetIdentityVerificationSession,
  useUpdateIdentityVerificationSession,
} from '@src/api/identity-verification-session-api'
import { reportErrorToConsole } from '@src/services/error-logger'
import { isJobValid } from './vouched-selectors'

export const useScript = (src: string) => {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    const script = document.createElement('script')
    script.src = src
    script.async = true
    script.onload = () => setLoaded(true)
    document.head.appendChild(script)

    return () => {
      document.head.removeChild(script)
    }
  }, [src])

  return loaded
}

export const useDefaultConfig = (creditApp: FilteredCreditApplication, session: IdentityVerificationSession | null) => {
  const { i18n } = useTranslation()
  const navigate = useNavigate()
  const [prequalify] = usePrequalify()
  const [downloadVouchedReport] = useDownloadVouchedReport()
  const [setSession] = useUpdateIdentityVerificationSession()
  const resetIdentityVerificationSession = useResetIdentityVerificationSession(creditApp.id)
  const { t } = useTranslation()
  const config = useMemo((): VouchedConfig => {
    return {
      onInit: (args) => {
        if (args.token !== session?.token) {
          setSession({ id: creditApp.id, token: args.token }).catch(reportErrorToConsole)
        }
      },
      onDone: (job) => {
        const replace = { replace: true }
        resetIdentityVerificationSession()
        if (isJobValid(job)) {
          prequalify({ creditApplicationId: creditApp.id, vouchedJobToken: job.token })
            .then(() => navigate(`/creditApplication/${creditApp.id}`, replace))
            .catch(reportErrorToConsole)
        } else {
          downloadVouchedReport({ creditApplicationId: creditApp.id, vouchedJobToken: job.token }).catch(
            reportErrorToConsole,
          )
          navigate(`/creditApplication/${creditApp.id}/failedid`, replace)
        }
      },
      token: session?.token,
      appId: import.meta.env.VITE_VOUCHED_PUBLIC_API_KEY,
      callbackURL: `${import.meta.env.VITE_BACKEND_API_URL}/api/creditapplication/${creditApp.id}/VouchedCallback`,
      verification: {
        firstName: creditApp.applicant.firstName,
        lastName: creditApp.applicant.lastName,
      },
      content: {
        progressIndicatorLoading: 'loading',
        progressIndicatorVouching: 'loading',
        qrHandoffInstructions: t('vouched.content.qrHandoffInstructions'),
      },
      locale: i18n.language,
      liveness: 'enhanced',
      idLiveness: 'distance',
      id: 'camera',
      face: 'camera',
      maxRetriesBeforeNext: 99,
      numForceRetries: 99,
      manualCaptureTimeout: 360000,
      showProgressBar: true,
      showUploadFirst: true,
      enableEyeCheck: true,
      includeBackId: true,
      includeBarcode: false,
      crossDevice: true,
      crossDeviceQRCode: true,
      crossDeviceSMS: true,
      userConfirmation: {
        confirmData: true,
        confirmImages: true,
      },
      theme: {
        name: 'avant',
        iconLabelColor: '#413d3a',
        bgColor: '#FFFFFF',
        navigationActiveText: '#08ABF0',
        iconColor: '#f6f5f3',
        iconBackground: '#08ABF0',
        baseColor: '#08ABF0',
        fontColor: '#413d3a',
        font: 'Open Sans',
        navigationActiveBackground: '#08ABF0',
        navigationDisabledBackground: '#e0e0e0',
        navigationDisabledText: '#a0a0a0',
        secondaryButtonColor: 'rgb(216, 216, 216)',
        handoffLinkColor: '#08ABF0',
        progressIndicatorTextColor: '#000',
      },
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language])

  return { config }
}
