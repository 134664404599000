import translate from '@src/services/translate'
import { TOptionsBase } from 'i18next'
import { $Dictionary } from 'node_modules/i18next/typescript/helpers'
import { FieldError } from 'react-hook-form'

export * from './constants'

export function translateErrorCode(err: FieldError, errorParams?: TOptionsBase & $Dictionary): string {
  if (!err) return ''
  if (err.type === 'required') return translate('common.errors.required', errorParams)
  if (err.type === 'test-Sin-type') return translate('common.errors.number', errorParams)
  return err.message ? translate(err.message) : ''
}

export const EMPTY_ARRAY = []

export enum EApplicationStep {
  Credit = 'credit',
  Finaning = 'financing',
  CVT = 'CVT',
  Payout = 'payout',
}

export type CustomError = Error & { id?: string }
