import ReviewItems from '@src/components/ReviewItems'
import useFaqLink from '@src/hooks/useFaqLink'
import { PHONE_NUMBER, PHONE_NUMBER_LINK } from '@src/types'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

interface Props {
  creditAppId: string
}

const VouchedPreparePage = ({ creditAppId }: Props) => {
  const { t } = useTranslation()
  const faqLink = useFaqLink()
  return (
    <main style={{ flexGrow: 1 }} className="step-0">
      <h1>{t('identityValidation.title')}</h1>
      <div className="paragraph" style={{ marginTop: '1rem', maxWidth: '100%' }}>
        <p>{t('identityValidation.whatYouNeed')}</p>
      </div>
      <ReviewItems label={t('identityValidation.validID')} icon="address-card" />
      <Link to={`/creditApplication/${creditAppId}/vouchedPage`} className="btn btn-blue">
        {t('common.continue')}
        <i className="fa-regular fa-arrow-right" />
      </Link>

      <section>
        <p style={{ marginTop: '2rem', fontSize: '1.2rem' }}>
          <Trans
            i18nKey="identityValidation.poweredBy"
            components={{
              site_anchor: <Link to="https://www.vouched.id/" target="_blank" />,
            }}
          />
        </p>
        <div style={{ fontSize: '1.5rem', marginTop: '1rem' }}>
          <Trans
            i18nKey="identityValidation.question"
            components={{
              site_anchor: <Link to={faqLink} target="_blank" />,
            }}
          />
          <div style={{ marginTop: '8px' }}>
            <Trans
              i18nKey="identityValidation.contactUs"
              values={{
                phoneNumber: PHONE_NUMBER,
              }}
              components={{
                a: <Link to={PHONE_NUMBER_LINK} style={{ textWrap: 'nowrap' }} />,
              }}
            />
          </div>
        </div>
      </section>
    </main>
  )
}

export default VouchedPreparePage
