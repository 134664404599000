import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { OriginationTracking } from '@src/types/origination'
import { ESessionStorageKeys } from '@src/types'
import { useGetCreditApplicationById } from '@src/api/credit-api'

export interface TAppStore {
  origination: OriginationTracking | null
  setOrigination: (origination: OriginationTracking) => void
}

export const AppContext = createContext<TAppStore | null>(null)

export function useAppStoreProvider(initialOrigination: OriginationTracking | null, creditAppId?: string | null) {
  const [crediApp] = useGetCreditApplicationById(creditAppId ?? '')
  const [origination, setOrigination] = useState<OriginationTracking | null>(
    initialOrigination ??
      (sessionStorage.getItem(ESessionStorageKeys.ORIGINATION)
        ? (JSON.parse(sessionStorage.getItem(ESessionStorageKeys.ORIGINATION)!) as OriginationTracking)
        : null),
  )

  useEffect(() => {
    if (initialOrigination) {
      sessionStorage.setItem(ESessionStorageKeys.ORIGINATION, JSON.stringify(initialOrigination))
    }
  }, [initialOrigination])

  useEffect(() => {
    if (crediApp?.origination) {
      setOrigination(crediApp.origination)
    }
  }, [crediApp?.origination])

  return useMemo(
    () => ({
      origination,
      setOrigination,
    }),
    [origination, setOrigination],
  )
}

export function useAppStore(): TAppStore {
  return useContext(AppContext)!
}
