interface Props {
  title: string
}

const PageSectionTitle = ({ title }: Props) => {
  return (
    <div style={{ textAlign: 'center', width: '100%' }}>
      <h4 className="subtitle">{title}</h4>
    </div>
  )
}

export default PageSectionTitle
