import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { applicantFlinksId } from '@src/data/creditapp-selectors'
import Loader from '@src/components/Loader'
import { EFlinksEventStep } from '@src/types'
import {
  CompleteBankAccountRequestDto,
  useCompleteApplicantFlinks,
  useGetCreditApplicationById,
} from '@src/api/credit-api'
import { useNavigate, useParams } from 'react-router-dom'
import { reportErrorToConsole } from '@src/services/error-logger'
import { getRuntimeVariable } from '@src/services/utils'

const FlinksIncomeValidationPage = (): JSX.Element => {
  const { t, i18n } = useTranslation()
  const params = useParams()
  const navigate = useNavigate()
  const id = params.id!
  const [completeFlinks, isCompletingFlinks] = useCompleteApplicantFlinks()
  const [creditApp, isLoadingApp] = useGetCreditApplicationById(id, false)
  const externalServiceId = applicantFlinksId(creditApp)

  useEffect(() => {
    window.addEventListener('message', (e: MessageEvent<{ step?: EFlinksEventStep }>) => {
      if (e.data?.step) {
        // The request is done, we are receiving the answer
        if (e.data.step === EFlinksEventStep.REDIRECT) {
          const completeFlinksDto = {
            id,
            externalServiceId,
          } as CompleteBankAccountRequestDto

          completeFlinks(completeFlinksDto).catch(reportErrorToConsole)

          navigate(`/creditapplication/${id}/flinks/status`, { replace: true })
        }
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isLoadingApp) return <Loader title={t('common.withYouShortly')} description={t('common.pleaseWait')} />

  const iframeUrl =
    import.meta.env.VITE_FLINKS_IFRAME +
    `?theme=light
  &desktopLayout=true
  &customerName=iFinance
  &institutionFilterEnable=true
  &demo=${getRuntimeVariable(import.meta.env.VITE_USE_DEMO_FLINKS) ? 'true' : 'false'}
  &consentEnable=true
  &accountSelectorEnable=true
  &daysOfTransactions=Days365
  &monthsOfStatements=Months12
  &enhancedMFA=true
  &staticLoadingEnable=true
  &tag=${import.meta.env.VITE_ENV}_${externalServiceId}
  &language=${i18n.language}
  &scheduleRefresh=false`
      .replace(/ /g, '')
      .replace(/(\r\n|\n|\r)/gm, '')

  if (isCompletingFlinks) return <Loader title={t('common.withYouShortly')} description={t('common.pleaseWait')} />
  return <iframe title="flinksIFrame" loading="eager" src={iframeUrl} className="full-page-iframe" />
}

export default FlinksIncomeValidationPage
