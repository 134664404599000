import { useEffect, useMemo, useState } from 'react'
import { RadioButtonListOption } from '@src/components/RadioButtonList'
import { formatDate } from '@src/services/Formatter'
import { compareAsc, format } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { UseFormTrigger } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { reportErrorToConsole } from '@src/services/error-logger'
import { useGetHolidaysForYear } from '@src/api/holidays-api'
import { useFeatureFlags } from '@src/api/FeatureSwitch-api'
import { computeFirstPaymentDateOptions, getClosestDeliveryOn } from './selectors'
import { WorksheetForm } from './schema-hooks'

export enum EWorksheetStep {
  AmountRequested = 'amount-requested',
  FirstPayment = 'first-payment',
  PayOverTime = 'pay-over-time',
  ProtectionPlan = 'protection-plan',
  Summary = 'summary',
}

export const EWorksheetStepList = Object.values(EWorksheetStep)

export const DEFAULT_STEP = EWorksheetStep.AmountRequested

export const useComputeFirstPaymentDateOptions = (activationDate: string, listHolidays: Date[]) => {
  const { t } = useTranslation()
  return useMemo(() => {
    const dateList = computeFirstPaymentDateOptions(activationDate, listHolidays)

    const dateListString: RadioButtonListOption[] = dateList.map<RadioButtonListOption>((date, index, arr) => {
      const option: RadioButtonListOption = {
        id: `${formatDate(date)}T08:00:00`,
        primaryText: format(date, 'PPPP'),
      }
      if (index === arr.length - 1) option.ternaryText = t('worksheet.firstPayment.nextMonthPromo')
      return option
    })

    return dateListString
  }, [activationDate, listHolidays, t])
}

type InputName = keyof WorksheetForm

const InputsByStepMapping: Record<EWorksheetStep, InputName[]> = {
  [EWorksheetStep.AmountRequested]: ['amountRequested'],
  [EWorksheetStep.FirstPayment]: ['firstPaymentOn'],
  [EWorksheetStep.PayOverTime]: ['term'],
  [EWorksheetStep.ProtectionPlan]: ['includeInsurance'],
  [EWorksheetStep.Summary]: [],
}

export const useWorksheetStepIsValid = (
  currentStep: string,
  steps: string[],
  trigger: UseFormTrigger<WorksheetForm>,
) => {
  const navigate = useNavigate()
  const inputListToValidate = useMemo<InputName[]>(() => {
    if (!steps.length) return []
    const inputNameList: InputName[] = []
    const currentStepIndex = steps.indexOf(currentStep)
    for (let i = 0; i < currentStepIndex; i += 1) {
      inputNameList.push(...InputsByStepMapping[steps[i] as EWorksheetStep])
    }

    return inputNameList
  }, [currentStep, steps])

  useEffect(() => {
    trigger(inputListToValidate)
      .then((isValid) => {
        if (!isValid) navigate(`../worksheet/${DEFAULT_STEP}`, { replace: true })
      })
      .catch(reportErrorToConsole)
  }, [inputListToValidate, navigate, trigger])
}

export const useIsReportExpired = (hardHitReportReceivedOn: Date) => {
  const [currentDate] = useState(new Date())
  const [listHolidays] = useGetHolidaysForYear(currentDate.getFullYear())
  const [flags] = useFeatureFlags()

  const isExpired = useMemo(() => {
    const soonestDeliveryOn = getClosestDeliveryOn(
      currentDate,
      listHolidays,
      flags?.useAllowWeekendOrHolidayActivationDate === true,
    )
    const receivedOnPlus90Days = new Date(hardHitReportReceivedOn)
    receivedOnPlus90Days.setDate(receivedOnPlus90Days.getDate() + 90)

    return compareAsc(soonestDeliveryOn, receivedOnPlus90Days) === 1
  }, [currentDate, listHolidays, hardHitReportReceivedOn, flags])

  return isExpired
}
