import { useLayoutEffect } from 'react'
import { Route, Routes } from 'react-router-dom'
import { scrollToTop } from '@src/services/utils'
import { FilteredCreditApplication, RequiredDocumentWithStatus } from '@src/api/types/FilteredCreditApplication'
import WorksheetPage from '@src/containers/Worsheet/WorksheetPage'
import OverviewPage from './OverviewPage/OverviewPage'
import DocumentsPage from './components/DocumentsPage'
import ApplicantIncomePage from './components/ApplicantIncomePage'
import CustomerFeedbackPage from './components/CustomerFeedbackPage'
import CreditSummaryPage from './components/CreditSummaryPage'

interface Props {
  creditApp: FilteredCreditApplication
  requiredDocuments: RequiredDocumentWithStatus[]
}

const HardHitApprovedResultPage = ({ creditApp, requiredDocuments }: Props) => {
  useLayoutEffect(scrollToTop, [])

  return (
    <Routes>
      <Route path="credit-summary" element={<CreditSummaryPage creditApp={creditApp} />} />
      <Route path="required-documents" element={<DocumentsPage creditApp={creditApp} />} />
      <Route path="feed-back" element={<CustomerFeedbackPage creditApp={creditApp} />} />

      <Route path="incomes" element={<ApplicantIncomePage creditApp={creditApp} />} />
      <Route path="worksheet/:step?" element={<WorksheetPage creditApp={creditApp} />} />

      <Route index path="*" element={<OverviewPage creditApp={creditApp} requiredDocuments={requiredDocuments} />} />
    </Routes>
  )
}

export default HardHitApprovedResultPage
