import { useCallback, useLayoutEffect, useMemo, useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { scrollToTop } from '@src/services/utils'
import { useTranslation } from 'react-i18next'
import { useGetProvincesConfigs } from '@src/api/config-api'
import { ESessionStorageKeys } from '@src/types/constants'
import {
  Prequalification,
  PrequalificationSchema,
  Applicant,
  PersonalInformationInput,
} from '../../types/CreditApplicationSchema'
import LoanAmountForm from './components/LoanAmountForm'
import PersonalInformationForm from './components/PersonalInformationForm'
import AddressForm from './components/AddressForm'
import EmploymentForm from './components/EmploymentForm'
import ReviewPrequalification from './components/ReviewPrequalification'
import StepProgressionBar from './components/StepProgressionBar'
import StepperFormFooter from './components/StepperFormFooter'
import ContactInformationForm from './components/ContactInformationForm'
import { useSessionStorage } from '../InitialApprobationPage/hooks'

enum Step {
  LoanAmount = 'loan-amount',
  PersonalInformation = 'personal-information',
  Contact = 'contact',
  Address = 'address',
  Employment = 'employment',
  Review = 'review',
}

const STEP_LIST = Object.values(Step)

const Apply = () => {
  const { t } = useTranslation()
  const { currentstep: paramCurrentStep } = useParams()
  const currentStep: Step = (paramCurrentStep as Step) || Step.LoanAmount
  const [provinceConfigs] = useGetProvincesConfigs()
  const [prequalInfo, setPrequalInfo] = useSessionStorage<Prequalification>(ESessionStorageKeys.PREQUAL_INFO, {
    ...PrequalificationSchema(provinceConfigs).getDefault(),
    origination: null,
  })
  const navigate = useNavigate()

  const currentStepIndex = useMemo(() => {
    return STEP_LIST.findIndex((x) => x === currentStep) + 1
  }, [currentStep])
  const [disableNext, setDisableNext] = useState(false)

  useLayoutEffect(scrollToTop, [])

  useEffect(() => {
    const savedPrequalInfo = sessionStorage.getItem(ESessionStorageKeys.PREQUAL_INFO)
    if (!savedPrequalInfo) navigate(`/apply/loan-amount`, { replace: true })
  }, [navigate])

  const gotoStep = useCallback(
    (stepPath: string) => {
      scrollToTop()

      navigate(`/apply/${stepPath}`)
    },
    [navigate],
  )

  const nextStepAfter = useCallback(
    (cCurrentStep: string) => {
      const nextStepIdx = STEP_LIST.indexOf(cCurrentStep.toLowerCase() as Step) + 1
      if (nextStepIdx < STEP_LIST.length) {
        gotoStep(STEP_LIST[nextStepIdx])
      }
    },
    [gotoStep],
  )

  const handlePrequalificationUpdated = (data: Partial<Prequalification>) => {
    const update = { ...prequalInfo, ...data }
    setPrequalInfo(update)
    nextStepAfter(currentStep)
  }

  const handleApplicantUpdated = (data: Partial<Applicant>) => {
    const update = { ...prequalInfo, applicant: { ...prequalInfo.applicant, ...data } }
    setPrequalInfo(update)
    nextStepAfter(currentStep)
  }

  return (
    <main className={`form-section step-${currentStepIndex}-active`}>
      <div className="strong text-center" style={{ marginBottom: '3rem', fontSize: '0.9em' }}>
        {t('eligibility.preApproved')}
      </div>
      <StepProgressionBar stepIndex={currentStepIndex} />
      {currentStep === Step.LoanAmount && (
        <LoanAmountForm
          prequalificationData={prequalInfo}
          onPrequalificationUpdated={handlePrequalificationUpdated}
          nextStepAfter={nextStepAfter}
          currentStep={currentStep}
        />
      )}
      {currentStep === Step.PersonalInformation && (
        <PersonalInformationForm
          prequalificationData={prequalInfo}
          onPrequalificationUpdated={(data: PersonalInformationInput) =>
            handlePrequalificationUpdated({ applicant: { ...prequalInfo.applicant, ...data.applicant } })
          }
          nextStepAfter={nextStepAfter}
          currentStep={currentStep}
          provinceConfigs={provinceConfigs}
        />
      )}

      {currentStep === Step.Contact && (
        <ContactInformationForm onApplicantUpdated={handleApplicantUpdated} applicantData={prequalInfo.applicant} />
      )}
      {currentStep === Step.Address && (
        <AddressForm
          applicantData={prequalInfo.applicant}
          onApplicantUpdated={handleApplicantUpdated}
          applicantName={prequalInfo.applicant.firstName}
          nextStepAfter={nextStepAfter}
          currentStep={currentStep}
          setDisableNext={setDisableNext}
          provinceConfigs={provinceConfigs}
        />
      )}

      {currentStep === Step.Employment && (
        <EmploymentForm
          applicantData={prequalInfo.applicant}
          onApplicantUpdated={handleApplicantUpdated}
          nextStepAfter={nextStepAfter}
          currentStep={currentStep}
        />
      )}

      {currentStep === Step.Review && <ReviewPrequalification gotoStep={gotoStep} prequalification={prequalInfo} />}

      {currentStep !== Step.Review && (
        <StepperFormFooter
          currentStepIndex={currentStepIndex}
          stepLength={STEP_LIST.length}
          disableBack={currentStepIndex === 1}
          disableNext={disableNext}
          setDisableNext={setDisableNext}
        />
      )}
    </main>
  )
}

export default Apply
